<template>
    <div>
        <b-row>
            <b-col>
                <vue-good-table :columns="header" :rows="columnData" @on-row-click="onRowClick" :total-rows="pageTotal" :per-page="pageLength" :pagination-options="{
                    enabled: true,
                }">
                    <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap">
                                    Showing 1 to
                                </span>
                                <b-form-select v-model="pageLength" :options="['5', '10', '20', '50', '100']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                            </div>

                            <b-pagination :value="1" :total-rows="pageTotal" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </div>
                    </template>
                </vue-good-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { getReferral } from "@/services/AgentService";
import { BCol, BFormSelect, BModal, BPagination, BRow } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
export default {
    name: "Referral",
    components: {
        BRow,
        BCol,
        BPagination,
        BFormSelect,
        BModal,
        VueGoodTable,
    },
    data() {
        return {
            pageTotal: 0,
            pageLength: 10,
            columnData: [{}],
            modal: {},
        };
    },
    async mounted() {
        this.columnData = await getReferral();
        this.pageTotal = this.columnData.length;
    },
    computed: {
        isMobile() {
            const currentBreakPoint = this.$store.getters["app/currentBreakPoint"];
            const isMobile = currentBreakPoint === "xs";
            return isMobile;
        },
        header() {
            return [
                {
                    label: "Affiliate Code",
                    field: "affiliateCode",
                },
                {
                    label: "Amount",
                    field: "amount",
                },
            ];
        },
    },

};
</script>
